import React from "react";

class noMatch extends React.Component {
  render() {
    return (
      <div className="ui content">
        <h2>Sorry, this route doesn't exist</h2>
      </div>
    );
  }
}

export default noMatch;
